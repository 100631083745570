.league-button:hover {
    cursor: pointer;
    background-color: #e6dc38 !important;
}

div.clicked {
    background-color: #81ff94 !important;
}

div.clicked:hover {
    background-color: #64ffcf !important;
}